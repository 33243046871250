<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.username"
                :label="$t('username')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('username') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.password"
                :label="$t('password')"
                :append-icon="show_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pwd ? 'text' : 'password'"
                @click:append="show_pwd = !show_pwd"
                :rules="rules.password"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('password') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="item.role"
                :label="$t('account.role')"
                :items="role_items"
                :item-text="item => $t(item.text)"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('account.role') }}
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="item.user_language"
                :label="$t('account.language')"
                :items="language_items"
                :item-text="item => $t(item.text)"
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-text-field
                v-model="item.first_name"
                :label="$t('first_name')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.last_name"
                :label="$t('last_name')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.email"
                :rules="[rules.required, rules.email]"
                :label="$t('email')"
              ></v-text-field>
            </v-col>
          </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { role_items, language_items } from "@/definition.js"
import { DatePicker } from "v-calendar";
import BaseDialog from "./BaseDialog";
export default {
  props: {},
  data: (vm) => ({
    topic: vm.$i18n.t("account.account"),
    show_pwd: false,
    role_items: role_items,
    language_items: language_items,
  }),
  components: {
    DatePicker,
  },
  mixins: [BaseDialog],
};
</script>
