var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")],1)],1),_c('AccountDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('account.list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.role,_vm.role_items))))]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),(item.end_user_status===1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.disableUser(item,_vm.$t('account.account'),item.username)}}},on),[_vm._v("mdi-account-off")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("disable")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.enableUser(item,_vm.$t('account.account'),item.username)}}},on),[_vm._v("mdi-account-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("enable")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }