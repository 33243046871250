<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <AccountDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></AccountDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('account.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.role="{ item }">{{
          item.role | getText(role_items) | Translate
        }}</template>
        <template v-slot:item.fullname="{ item }">
            {{item.first_name}} {{item.last_name}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.end_user_status===1">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="disableUser(item,$t('account.account'),item.username)"
                >mdi-account-off</v-icon
              >
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="enableUser(item,$t('account.account'),item.username)"
                >mdi-account-check</v-icon
              >
            </template>
            <span>{{ $t("enable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import AccountDialog from "../dialogs/AccountDialog"
import BaseTable from "./BaseTable.vue";
import {role_items} from "@/definition.js"
export default {
  data: vm => ({
    url: "/account/users/",
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("ID"),
        value: "id",
        width: "10%"
      },
      {
        sortable: false,
        text: vm.$i18n.t("account.username"),
        value: "username",
        width: "10%"
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("account.fullname"),
      //   value: "fullname",
      //   width: "10%"
      // },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("account.email"),
      //   value: "email",
      //   width: "10%"
      // },
      {
        sortable: false,
        text: vm.$i18n.t("account.role"),
        value: "role",
        width: "10%"
      },
      {
        sortable: false,
        text: vm.$i18n.t("account.last-login"),
        value: "last_login",
        width: "10%"
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "5%"
      }
    ],
    role_items: role_items,
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    AccountDialog,
    CommonAlertDialog
  },
  methods: {
    disableUser (item,key="",value="") {
      if (confirm(this.$i18n.t('disable-confirm',{key:key,value:value}))) {
        this.action = this.action_items.edit
        var disable_item = {
          id: item.id,
          end_user_status: 2
        }
        this.onSaveEvent(disable_item)
      }
    },
    enableUser (item,key="",value="") {
      if (confirm(this.$i18n.t('enable-confirm',{key:key,value:value}))) {
        this.action = this.action_items.edit
        var disable_item = {
          id: item.id,
          end_user_status: 1
        }
        this.onSaveEvent(disable_item)
      }
    },
  },
  mounted() {
  }
}
</script>
